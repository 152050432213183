import { createFeatureSelector, createSelector } from '@ngrx/store';
import { orderBy, uniqBy } from 'lodash';
import { DateTime } from 'luxon';
import { environment } from 'src/config/environment';
import { ZodIssue } from 'zod';
import { NEGOTIATION_DURATION } from '../../constants/orders/negotiation-duration';
import { CompanyType } from '../../enums/auth/company-type';
import { NegotiationStatus } from '../../enums/orders/negotiation-status';
import { OrderAssignment } from '../../enums/orders/order-assignment';
import { OrderStatus, getOrderStatus } from '../../enums/orders/order-status';
import { OrdersCount } from '../../enums/orders/orders-count';
import { OrdersSorting } from '../../enums/orders/orders-sorting';
import { VehicleType } from '../../enums/vehicles/vehicle-type';
import { User } from '../../interfaces/auth/user';
import {
  FirebaseOrder,
  FirebaseOrderSchema,
} from '../../interfaces/orders/firebase-order';
import { Order } from '../../interfaces/orders/order';
import { OrdersAssignmentSummary } from '../../interfaces/orders/orders-assignment-summary';
import { OrdersFilters } from '../../interfaces/orders/orders-filters';
import { UnassignedReasons } from '../../interfaces/orders/unassigned-reasons';
import { Badge } from '../../interfaces/utilities/badge';
import { Sorting } from '../../interfaces/utilities/sorting';
import { FirebaseSolutionPoint } from '../../interfaces/vehicles/firebase-solution-point';
import { FirebaseVehicle } from '../../interfaces/vehicles/firebase-vehicle';
import { RoutePoint } from '../../interfaces/vehicles/route-point';
import { selectOrdersAssignments } from '../assignments/assignments.selectors';
import { AssignmentsState } from '../assignments/assignments.state';
import { selectUser } from '../auth/auth.selectors';
import { selectPlannerLoading } from '../planner/planner.selectors';
import { selectRejections } from '../rejections/rejections.selectors';
import { RejectionsState } from '../rejections/rejections.state';
import { selectSolutions } from '../solutions/solutions.selectors';
import { SolutionsState } from '../solutions/solutions.state';
import { selectUnassignedReasons } from '../unassigned-reasons/unassigned-reasons.selectors';
import { UnassignedReasonsState } from '../unassigned-reasons/unassigned-reasons.state';
import { selectVehiclesState } from '../vehicles/vehicles.selectors';
import { VehiclesState } from '../vehicles/vehicles.state';
import { OrdersState } from './orders.state';

const selectOrdersState = createFeatureSelector<OrdersState>('orders');

export const selectAllOrders = createSelector(
  selectPlannerLoading,
  selectUser,
  selectOrdersState,
  selectOrdersAssignments,
  selectRejections,
  selectSolutions,
  selectUnassignedReasons,
  selectVehiclesState,
  (
    loading,
    user,
    ordersState,
    assignments,
    rejections,
    solutions,
    unassignedReasons,
    vehicles,
  ): Order[] | undefined => {
    if (loading || user == null) return undefined;

    return Object.values(ordersState)
      .map((firebaseOrder): Order => {
        let order: Order = { ...firebaseOrder } as Order;

        const { isRejected, vehicle, solution } = getVehicle(
          firebaseOrder,
          assignments,
          vehicles,
          rejections,
          solutions,
        );
        const errors = getErrors(firebaseOrder, vehicles, vehicle);
        const unassignmentReasons = getUnassignmentReasons(
          firebaseOrder,
          unassignedReasons,
        );
        const rejectedVehicles = getRejectedVehicles(
          firebaseOrder,
          rejections,
          vehicles,
        );
        const status = getStatus(
          firebaseOrder,
          user,
          isRejected,
          vehicle,
          unassignmentReasons,
          errors,
        );
        const assignment = getAssignment(firebaseOrder, status, vehicle);
        const emptyDistance = getEmptyDistance(firebaseOrder, solution);
        const route = getRoute(order, solution, emptyDistance);
        const negotiationNeedsAttention = getNegotiationNeedsAttention(
          order,
          user,
        );

        Object.assign(order, {
          errors,
          status,
          unassignmentReasons,
          rejectedVehicles,
          assignment,
          emptyDistance,
          vehicle,
          route,
          negotiationNeedsAttention,
        });

        if (
          order.status.code !== OrderStatus.AWAITING_APPROVAL ||
          user.client === order.companyId
        ) {
          return order;
        }

        if (order.negotiation == null) {
          fetch(`${environment.apiUrl}/negotiations/${order.uuid}/start`, {
            method: 'POST',
            headers: { Authorization: `Bearer ${user.token}` },
          });
        } else if (
          ![
            NegotiationStatus.SENDER_ACCEPTED,
            NegotiationStatus.COMPLETED,
          ].includes(order.negotiation.status)
        ) {
          const negotiationDuration = DateTime.fromISO(
            order.negotiation.createdAt,
          )
            .diffNow()
            .negate();
          if (negotiationDuration > NEGOTIATION_DURATION) {
            fetch(`${environment.apiUrl}/negotiations/${order.uuid}/expire`, {
              method: 'POST',
              headers: { Authorization: `Bearer ${user.token}` },
            });
          }
        }

        return order;
      })
      .filter(
        (order) =>
          user.company?.id == null ||
          user.company.id === order.companyId ||
          order.vehicle?.companyId === user.company.id,
      );
  },
);

export const selectOrders = (options: {
  status: OrderStatus;
  vehicleTypes?: VehicleType[];
  filters?: OrdersFilters;
  sorting?: Sorting<OrdersSorting>;
}) =>
  createSelector(selectAllOrders, selectUser, (orders, user) => {
    const { status, vehicleTypes, filters, sorting } = options;

    const filtersChecks = getFiltersChecks(status, vehicleTypes, filters);
    const filteredOrders = orders?.filter((order) =>
      filtersChecks.every((filter) => filter(order)),
    );

    if (status === OrderStatus.AWAITING_APPROVAL && user?.company != null) {
      return orderBy(
        filteredOrders,
        [
          (order) => order.negotiationNeedsAttention,
          (order) =>
            order.negotiation?.status !== NegotiationStatus.SENDER_ACCEPTED,
          (order) => order.negotiation?.createdAt,
        ],
        ['desc', 'desc', 'asc'],
      );
    }

    const [sort, order] = [sorting?.sort, sorting?.order];
    switch (sort) {
      case OrdersSorting.ID:
        return orderBy(filteredOrders, 'id', order);
      case OrdersSorting.VEHICLE_ID:
        return orderBy(filteredOrders, 'vehicle.id', order);
      case OrdersSorting.LOADING_DATE:
        return orderBy(filteredOrders, 'pickupTimeWindow.start', order);
      case OrdersSorting.UNLOADING_DATE:
        return orderBy(filteredOrders, 'deliveryTimeWindow.start', order);
      case OrdersSorting.DISTANCE:
        return orderBy(filteredOrders, 'distance', order);
      case OrdersSorting.WEIGHT:
        return orderBy(filteredOrders, 'weight', order);
      default:
        return filteredOrders;
    }
  });

export const selectOrder = (orderUuid: string) =>
  createSelector(
    selectAllOrders,
    (orders) => orders?.filter((o) => o.uuid === orderUuid)?.[0],
  );

export const selectOrderChildren = (orderParentUuid: string) =>
  createSelector(
    selectAllOrders,
    (orders) => orders?.filter((o) => o.parentUuid === orderParentUuid),
  );

export const selectOrdersCount = createSelector(selectAllOrders, (orders) => {
  if (!orders) return undefined;

  const count: OrdersCount = Object.fromEntries(
    Object.values(OrderStatus).map((status) => [status, 0]),
  ) as OrdersCount;

  for (const order of orders) {
    ++count[order.status.code];
  }

  return count;
});

export const selectOrdersAssignmentsSummary = createSelector(
  selectOrders({ status: OrderStatus.IN_PROGRESS }),
  (orders) => {
    if (!orders) return undefined;

    const summary: OrdersAssignmentSummary = {
      [OrderAssignment.ONYX]: 0,
      [OrderAssignment.MANUAL]: 0,
    };

    for (const order of orders) {
      if (order.assignment === OrderAssignment.ONYX) {
        ++summary[OrderAssignment.ONYX];
      } else if (order.assignment === OrderAssignment.MANUAL) {
        ++summary[OrderAssignment.MANUAL];
      }
    }

    return summary;
  },
);

const getFiltersChecks = (
  status: OrderStatus,
  vehicleTypes?: VehicleType[],
  filters?: OrdersFilters,
): ((order: Order) => boolean)[] => {
  const filtersChecks: ((order: Order) => boolean)[] = [];

  if (filters?.id) {
    const id = filters.id.toLowerCase();
    filtersChecks.push((o) => o.id?.toLowerCase()?.includes(id));
  } else if (filters?.uuid) {
    const uuid = filters.uuid.toLowerCase();
    filtersChecks.push((o) => o.uuid?.startsWith(uuid));
  }

  if (filtersChecks.length !== 0) return filtersChecks;

  filtersChecks.push((o) => o.status.code === status);

  if (vehicleTypes && vehicleTypes.length !== 0) {
    filtersChecks.push((o) =>
      vehicleTypes.some((t) => o.vehicleType.includes(t)),
    );
  }

  if (filters?.assignment) {
    filtersChecks.push((o) => o.assignment === filters.assignment);
  }

  if (filters?.startCountryCode) {
    filtersChecks.push((o) =>
      filters.startCountryCode!.includes(o.from.countryCode.toLowerCase()),
    );
  }
  if (filters?.endCountryCode) {
    filtersChecks.push((o) =>
      filters.endCountryCode!.includes(o.to.countryCode.toLowerCase()),
    );
  }

  if (filters?.startDateFrom) {
    const startDateFrom = DateTime.fromJSDate(filters.startDateFrom);
    filtersChecks.push(
      (o) =>
        DateTime.fromJSDate(new Date(o.pickupTimeWindow.start))
          .startOf('day')
          .diff(startDateFrom)
          .toMillis() >= 0,
    );
  }
  if (filters?.startDateTo) {
    const startDateTo = DateTime.fromJSDate(filters.startDateTo);
    filtersChecks.push(
      (o) =>
        DateTime.fromJSDate(new Date(o.pickupTimeWindow.end))
          .startOf('day')
          .diff(startDateTo)
          .toMillis() <= 0,
    );
  }

  if (filters?.endDateFrom) {
    const endDateFrom = DateTime.fromJSDate(filters.endDateFrom);
    filtersChecks.push(
      (o) =>
        DateTime.fromJSDate(new Date(o.deliveryTimeWindow.start))
          .startOf('day')
          .diff(endDateFrom)
          .toMillis() >= 0,
    );
  }
  if (filters?.endDateTo) {
    const endDateTo = DateTime.fromJSDate(filters.endDateTo);
    filtersChecks.push(
      (o) =>
        DateTime.fromJSDate(new Date(o.deliveryTimeWindow.end))
          .startOf('day')
          .diff(endDateTo)
          .toMillis() <= 0,
    );
  }

  return filtersChecks;
};

const getErrors = (
  order: FirebaseOrder,
  vehicles: VehiclesState,
  vehicle?: FirebaseVehicle,
): ZodIssue[] | undefined => {
  if (order.wasUnloaded || order.wasSold) return undefined;

  const result = FirebaseOrderSchema.safeParse(order);
  const zodErrors: ZodIssue[] = (result as any)?.error?.issues ?? [];

  const customErrors: ZodIssue[] = [];
  if (order.assignedVehicleUuid && !vehicle) {
    const assignedVehicle = vehicles[order.assignedVehicleUuid];
    if (!assignedVehicle) {
      customErrors.push({
        code: 'custom',
        path: ['assignedVehicleUuid'],
        message: 'assignedVehicleUuid.nonexistent',
      });
    } else if (!order.vehicleType.includes(assignedVehicle.type)) {
      customErrors.push({
        code: 'custom',
        path: ['assignedVehicleUuid'],
        message: 'assignedVehicleUuid.invalidType',
      });
    }
  }

  const errors = [...zodErrors, ...customErrors];
  if (errors.length === 0) return undefined;

  return uniqBy(errors, (error) => error.message);
};

const getUnassignmentReasons = (
  order: FirebaseOrder,
  unassignedReasons: UnassignedReasonsState,
): UnassignedReasons | undefined => {
  const unassignmentReasons: UnassignedReasons | undefined =
    unassignedReasons[order.uuid];
  return unassignmentReasons;
};

const getRejectedVehicles = (
  order: FirebaseOrder,
  rejections: RejectionsState,
  vehicles: VehiclesState,
): string[] | undefined => {
  const rejectedVehicles: string[] | undefined = rejections[order.parentUuid];
  if (!rejectedVehicles) return undefined;

  return rejectedVehicles.map((vehicleUuid) => vehicles[vehicleUuid].id);
};

const getVehicle = (
  order: FirebaseOrder,
  assignments: AssignmentsState['orders'],
  vehicles: VehiclesState,
  rejections: RejectionsState,
  solutions: SolutionsState,
): {
  isRejected: boolean;
  vehicle?: FirebaseVehicle;
  solution?: FirebaseSolutionPoint[];
} => {
  if (order.wasSold) return { isRejected: false };

  const vehicleUuid: string | undefined = assignments[order.parentUuid];
  const rejectedVehicles = rejections[order.parentUuid] ?? [];
  const isRejected = rejectedVehicles.includes(vehicleUuid);

  let vehicle: FirebaseVehicle | undefined = undefined;
  let solution: FirebaseSolutionPoint[] | undefined = undefined;

  if (vehicleUuid && !isRejected) {
    vehicle = vehicles[vehicleUuid];
    solution = solutions[vehicle?.uuid];
  }

  return { isRejected, vehicle, solution };
};

const getStatus = (
  order: FirebaseOrder,
  user: User,
  isRejected: boolean,
  vehicle?: FirebaseVehicle,
  unassignmentReasons?: UnassignedReasons,
  errors?: ZodIssue[],
): Badge<OrderStatus> => {
  if (errors) {
    return getOrderStatus(OrderStatus.INVALID);
  } else if (order.wasUnloaded) {
    return getOrderStatus(OrderStatus.FINISHED);
  } else if (order.wasSold) {
    return getOrderStatus(OrderStatus.SOLD);
  } else if (
    order.wasLoaded ||
    order.assigned ||
    (order.assignedVehicleUuid && order.companyId === user.client)
  ) {
    return getOrderStatus(OrderStatus.IN_PROGRESS);
  } else if (isRejected) {
    return getOrderStatus(OrderStatus.SEARCHING_VEHICLE);
  } else if (vehicle) {
    return getOrderStatus(OrderStatus.AWAITING_APPROVAL);
  } else if (unassignmentReasons) {
    return getOrderStatus(OrderStatus.UNASSIGNED);
  } else {
    return getOrderStatus(OrderStatus.SEARCHING_VEHICLE);
  }
};

const getAssignment = (
  order: FirebaseOrder,
  status: Badge<OrderStatus>,
  vehicle: FirebaseVehicle | undefined,
): OrderAssignment | undefined => {
  if (!vehicle && !order.assignedVehicleUuid) return undefined;
  if (status.code === OrderStatus.AWAITING_APPROVAL)
    return OrderAssignment.ONYX;

  return order.assignedByOnyx ? OrderAssignment.ONYX : OrderAssignment.MANUAL;
};

const getEmptyDistance = (
  order: FirebaseOrder,
  solution?: FirebaseSolutionPoint[],
): number | undefined => {
  return (
    solution
      ?.filter((s) => s.orderUuid === order.uuid && s.emptyDistance)
      ?.reduce(
        (emptyDistance, point) => (emptyDistance += point.emptyDistance!),
        0,
      ) || undefined
  );
};

const getRoute = (
  order: Order,
  solution?: FirebaseSolutionPoint[],
  emptyDistance?: number,
): RoutePoint[] => {
  const orderSolution = solution?.filter((i) => i.orderUuid === order.uuid);
  return [
    {
      emptyDistance,
      orderUuid: order.uuid,
      pickup: true,
      timeWindow: order.pickupTimeWindow,
      estimatedTime: orderSolution?.filter((i) => i.pickup)?.[0]?.estimatedTime,
      address: order.from,
      completed: order.wasLoaded ?? false,
      order,
    },
    {
      orderUuid: order.uuid,
      pickup: false,
      timeWindow: order.deliveryTimeWindow,
      estimatedTime: orderSolution?.filter((i) => !i.pickup)?.[0]
        ?.estimatedTime,
      address: order.to,
      completed: order.wasUnloaded ?? false,
      order,
    },
  ];
};

const getNegotiationNeedsAttention = (order: Order, user: User): boolean => {
  const status = order.negotiation?.status;
  if (!status) return false;

  switch (user.company?.type) {
    case CompanyType.CARRIER:
      return [
        NegotiationStatus.INITIAL_OFFER,
        NegotiationStatus.FINAL_OFFER,
        NegotiationStatus.SENDER_ACCEPTED,
      ].includes(status);
    case CompanyType.SENDER:
      return [
        NegotiationStatus.COUNTER_OFFER,
        NegotiationStatus.CARRIER_ACCEPTED,
      ].includes(status);
    default:
      return false;
  }
};
